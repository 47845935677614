<template>
  <section class="create_pages">
    <div class="my_container">
      <h1>Create Page</h1>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              v-model="formData.head"
              label="Page Head"
              outlined
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              v-model="formData.page_name"
              label="Page Name"
              outlined
          ></v-text-field>
        </v-col>

        <!-- first image -->
        <v-col
            cols="12"
            sm="6"
        >
          <v-file-input
              v-model="formData.first_image"
              color="deep-purple accent-4"
              counter
              label="Image"
              placeholder="Select your files"
              prepend-icon=""
              outlined
              :show-size="1000"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>

        <!-- first section -->
        <v-col
            cols="12"
        >
          <vue-editor v-model="formData.first_section"></vue-editor>
        </v-col>

        <!-- two image -->
        <v-col
            cols="12"
            sm="6"
        >
          <v-file-input
              v-model="formData.two_image"
              color="deep-purple accent-4"
              counter
              label="Image"
              placeholder="Select your files"
              prepend-icon=""
              outlined
              :show-size="1000"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>

        <!-- two section -->
        <v-col
            cols="12"
        >
          <vue-editor v-model="formData.two_section"></vue-editor>
        </v-col>

        <!-- three image -->
        <v-col
            cols="12"
            sm="6"
        >
          <v-file-input
              v-model="formData.three_image"
              color="deep-purple accent-4"
              counter
              label="Image"
              placeholder="Select your files"
              prepend-icon=""
              outlined
              :show-size="1000"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>

        <!-- three section -->
        <v-col
            cols="12"
        >
          <vue-editor v-model="formData.three_section"></vue-editor>
        </v-col>

        <v-btn
            :loading="loading_form"
            :disabled="loading_form"
            color="blue-grey"
            outlined
            class="ma-2 white--text"
            @click="submit"
        >
          Add
          <v-icon
              right
              dark
          >
            mdi-cloud-upload
          </v-icon>
        </v-btn>

      </v-row>
    </div>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
import {create_new_page} from "@/api/admin";

export default {
  components: {
    VueEditor
  },
  data: () => ({
    loading_form: false,
    formData: {
      page_name: null,
      head: null,
      first_image: null,
      first_section: null,
    },
  }),
  methods: {
    submit() {
      this.loading_form = true;
      const form_data = new FormData();
      form_data.append('first_image', this.formData.first_image);
      form_data.append('first_section', this.formData.first_section);
      form_data.append('two_image', this.formData.two_image);
      form_data.append('two_section', this.formData.two_section);
      form_data.append('three_image', this.formData.three_image);
      form_data.append('three_section', this.formData.three_section);
      form_data.append('page_name', this.formData.page_name);
      form_data.append('head', this.formData.head);

      create_new_page(form_data)
          .then(() => {
            this.loading_form = false;
            alert('page has been created');
          }).catch(() => {
      })
    }
  }
};
</script>