var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"create_pages"},[_c('div',{staticClass:"my_container"},[_c('h1',[_vm._v("Create Page")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Page Head","outlined":""},model:{value:(_vm.formData.head),callback:function ($$v) {_vm.$set(_vm.formData, "head", $$v)},expression:"formData.head"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Page Name","outlined":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"Image","placeholder":"Select your files","prepend-icon":"","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.formData.first_image),callback:function ($$v) {_vm.$set(_vm.formData, "first_image", $$v)},expression:"formData.first_image"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('vue-editor',{model:{value:(_vm.formData.first_section),callback:function ($$v) {_vm.$set(_vm.formData, "first_section", $$v)},expression:"formData.first_section"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"Image","placeholder":"Select your files","prepend-icon":"","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.formData.two_image),callback:function ($$v) {_vm.$set(_vm.formData, "two_image", $$v)},expression:"formData.two_image"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('vue-editor',{model:{value:(_vm.formData.two_section),callback:function ($$v) {_vm.$set(_vm.formData, "two_section", $$v)},expression:"formData.two_section"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"Image","placeholder":"Select your files","prepend-icon":"","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.formData.three_image),callback:function ($$v) {_vm.$set(_vm.formData, "three_image", $$v)},expression:"formData.three_image"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('vue-editor',{model:{value:(_vm.formData.three_section),callback:function ($$v) {_vm.$set(_vm.formData, "three_section", $$v)},expression:"formData.three_section"}})],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading_form,"disabled":_vm.loading_form,"color":"blue-grey","outlined":""},on:{"click":_vm.submit}},[_vm._v(" Add "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }